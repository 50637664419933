<template>
  <label
    :class="baseLabelClass"
    :for="'delivery-radio-' + randomId"
    :data-testid="props.dataTestid + '-radio-label'"
  >
    <input
      :id="'delivery-radio-' + randomId"
      :data-testid="props.dataTestid + '-radio-input'"
      type="radio"
      v-model="modelValue"
      v-bind="$attrs"
      :class="baseInputClass"
    />
    <span class="flex items-center gap-4">
      <NuxtImg
        src="./images/customer-support-track-order.png"
        alt="add-to-trolley-modal-delivery-icon"
        width="32"
      />

      <span>
        <TsTypography as="span" variant="h4"> Delivery </TsTypography>

        <template v-if="isOutOfStock">
          <br />
          <TsTag
            label="Out of stock for delivery"
            variant="danger"
            :data-testid="props.dataTestid + '-out-of-stock-tag'"
            append-class="outline-0"
            size="xs"
            rounded
            outlined
          />
        </template>

        <template v-else>
          <TsTypography variant="h4" as="span"
            >by
            <span class="text-primary">Tomorrow</span>
          </TsTypography>
          <br />
          <!-- <TsTypography v-if="deliveryStock" as="span" size="sm">
          Order within
          <span class="text-idle-black">06h:18m</span> </TsTypography
          > -->
          <!-- <br /> -->
          <TsTypography
            as="span"
            size="sm"
            :data-testid="props.dataTestid + '-available-stock'"
          >
            {{ deliveryStock }}
          </TsTypography>
        </template>
      </span>
    </span>
  </label>
</template>

<script lang="ts" setup generic="T">
type Props = {
  deliveryStock?: string;
  isOutOfStock?: boolean;
  dataTestid: string;
};
const props = defineProps<Props>();

defineOptions({
  inheritAttrs: false,
});

const modelValue = defineModel<T>();

const baseInputClass =
  "size-5 text-info disabled:cursor-not-allowed bg-transparent focus:ring-info-border focus:ring-2";

const baseLabelClass =
  "flex items-center has-[:checked]:border-info w-full space-x-2.5 border hover:bg-info/10 transition-colors cursor-pointer has-[:disabled]:cursor-not-allowed p-3.5 rounded-md border-natural-light-grey";

// random ID for each Vue Instance
const randomId = ref<string>("");

onMounted(() => {
  randomId.value = useRandomUUID();
});
</script>
